var site = site || {};

site.template = site.template || {};
var generic = generic || {};

(function ($, Drupal) {
  'use strict';

  var siteEmailSignupV1 = {
    formatter: $('.site-email-signup-v1'),
    is_mobile: typeof site.client.isMobile == 'undefined' ? '0' : site.client.isMobile
  };

  Drupal.behaviors.siteEmailSignupV1 = {
    get: function (key) {
      return siteEmailSignupV1[key];
    },

    set: function (key, val) {
      siteEmailSignupV1[key] = val;
    },

    showEmailSignupContents: function (thisFormatter) {
      var self = this;
      var $triggerInput = thisFormatter.find('.js-gnav-util-trigger');
      var $triggerReset = thisFormatter.find('.js-gnav-util-trigger-reset');

      $(document).trigger('click');

      if (self.get('is_mobile') === 0) {
        thisFormatter.addClass('active');

        if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
          $triggerInput.prop('checked', true);
        }

        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');

        dimOverlay.prependTo(siteContainer);

        thisFormatter.bind('clickoutside', function () {
          thisFormatter.removeClass('active').unbind('clickoutside');
          dimOverlay.remove();
          $triggerReset.trigger('click');

          // user is closing the overlay after signing up so hide it
          self.hideGnavSignup(thisFormatter);
        });

        thisFormatter.find('.site-email-signup__contents-close').once().click(function (e) {
          e.preventDefault();
          thisFormatter.find('.js-email-signup').attr('aria-expanded', 'false').focus();
          thisFormatter.trigger('clickoutside');
        });
      }
    },

    hideGnavSignup: function (thisFormatter) {
      if (sessionStorage.getItem('userSignedUpGnav') === 1) {
        thisFormatter.addClass('hidden');
      }
    },

    setContentsOffset: function (thisFormatter) {
      var siteBag = $('.site-header .site-bag');
      var siteMyMac = $('.site-header .site-my-mac');
      var siteMyShades = $('.site-header .my-shades');
      var siteBagWidth,
        siteMyMacWidth,
        siteMyShadesWidth = 0;

      if (siteBag.length === 1) {
        siteBagWidth = siteBag.outerWidth(false);
      }
      if (siteMyMac.length === 1) {
        siteMyMacWidth = siteMyMac.outerWidth(false);
      }
      if (siteMyShades.length === 1) {
        siteMyShadesWidth = siteMyShades.outerWidth(false);
      }
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);

        // For each instance of email signup field, listen for successful gnav
        // data results.
        // Moved here by Bloom to allow gnavdata.js to not be coupled to any
        // implementations of its data
        $(window).on('gnavdata_success', function (e, gNavData) {
          sessionStorage.email_address = '';
          if (!_.isNull(gNavData) && !_.isEmpty(gNavData.EMAIL_ADDRESS)) {
            sessionStorage.email_address = gNavData.EMAIL_ADDRESS;
          }

          if (!_.isEmpty(sessionStorage.email_address)) {
            var emailField = thisFormatter.find('.return-user-email-newsletter');

            emailField.val(sessionStorage.email_address);
          }
        });

        // hide signup if the user has already done it this session
        self.hideGnavSignup(thisFormatter);

        // show overlay on click.
        thisFormatter.find('.site-email-signup__link').once().click(function () {
          if (!thisFormatter.hasClass('active')) {
            self.showEmailSignupContents(thisFormatter);
            $(this).children('.js-email-signup').attr('aria-expanded', 'true');
            site.restrict_navigation($('.js-email-signup-contents'));
          } else {
            thisFormatter.removeClass('active');
            thisFormatter.trigger('clickoutside');
          }

          return false;
        });
        $('.js-email-signup').focus(function () {
          $(this).trigger('mouseenter');
        });
        // set initial overlay offset
        self.setContentsOffset(thisFormatter);

        // show email terms on focus
        if (thisFormatter.find('.form-signup-email-promotions').length > 0) {
          thisFormatter.find('.email-address').focus(function () {
            thisFormatter.find('.form-signup-email-promotions').slideDown(300);
          });
        }

        // show checkbox when mobile input is focused
        if (thisFormatter.find('.form-signup-sms-promotions').length > 0) {
          thisFormatter.find('.mobile-number').focus(function () {
            thisFormatter.find('.form-signup-sms-promotions').slideDown(300);
          });
        }

        // handle user submitting the email/sms form
        thisFormatter.find('form').submit(function (submitEvt) {
          submitEvt.preventDefault();

          var params = {};

          $.each(thisFormatter.find('form').serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          // console.log(params);

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if (thisFormatter.find('form').find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (result) {
              // var results = result.getData();
              sessionStorage.setItem('userSignedUpGnav', 1);
              thisFormatter.find('.site-email-signup__contents-thank-you').removeClass('hidden');
              thisFormatter.find('.site-email-signup__contents-form').addClass('hidden');
              thisFormatter.find('.site-email-signup__contents-title').addClass('hidden');
            },
            onFailure: function (result) {
              // console.log(result.getMessages());
              var errorObjectsArray = result.getMessages();

              generic.showErrors(errorObjectsArray, null, null);
              generic.focusErrors($('.js-email-signup-errors'), $('.js-email-signup-container'));
              for (var i = 0; i < errorObjectsArray.length; i++) {
                var myErr = errorObjectsArray[i];

                if (myErr && myErr.key) {
                  if (myErr.key === 'required.pc_email_address.email_sms_signup' ||
                      myErr.key === 'invalid.pc_email_address.email_sms_signup') {
                    $('.js-email-newsletter').addClass('error').focus();
                  }
                  if (myErr.key === 'required_and.sms_promotions.mobile_number.required_and.email_sms_signup' ||
                      myErr.key === 'format.mobile_number.email_sms_signup' ||
                      myErr.key === 'min_max.mobile_number.email_sms_signup') {
                    $('.js-mobile-number').addClass('error').focus();
                  }
                }
              }
            }
          });
        });

        // global trigger to show overlay
        $(window).on('window::show_gnav_email_signup', function () {
          if (!thisFormatter.hasClass('active')) {
            self.showEmailSignupContents(thisFormatter);
          }
        });

        // hide overlay on resize, adjust overlay offset
        $(window).resize(_.debounce(function () {
          self.setContentsOffset(thisFormatter);
          if (thisFormatter.hasClass('active')) {
            thisFormatter.trigger('clickoutside');
          }
        }, 50));
      });

      if (Drupal.settings.globals_variables.gdpr_compliant) {
        $('.info-link').live('click', function (e) {
          e.preventDefault();
          var infoLink = $(e.currentTarget);

          function remInfoMessageBox() {
            if ($('#info-message-box').get(0)) {
              $('#info-message-box').remove();
            }
            if ($('#infoArrowBefore').get(0)) {
              $('#infoArrowBefore').remove();
            }
          }

          remInfoMessageBox();

          $("<div id='info-message-box'><div id='info-text'></div></div>").appendTo('body');
          var infoBoxId = $('#' + infoLink.attr('message-in'));
          var infoMessageBox = $('#info-message-box');

          $('#info-text').html("<div id='info-close'>X</div>" + infoBoxId.html());

          $('#cboxClose, #info-close').live('mousedown', function () {
            remInfoMessageBox();
          });
          function setInfoBoxPosition() {
            var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
            var infoLinkLeft = infoLink.offset().left;

            if (screen.width <= 640) {
              infoLinkLeft = 10;
              $('head').append("<style id='infoArrowBefore' type='text/css'>#info-message-box:before{left:" + (infoLink.offset().left - 10) + 'px;}</style>');
            } else if ((infoMessageBox.outerWidth(true) === 500) && ((infoLinkLeft + 500) > screen.width)) {
              infoLinkLeft = infoLinkLeft - 480;
              infoMessageBox.addClass('top-right-arrow');
            }
            infoMessageBox.css({
              top: infoLinkTop,
              left: infoLinkLeft
            });
          }
          setInfoBoxPosition();
          $(window).resize(function () {
            setInfoBoxPosition();
          });
        });
        $('#info-text span').live('mousedown', function () {
          window.location = $(this).attr('data-url');
        });
      }
    },

    attached: false
  };
})(jQuery, Drupal);
